.App {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  padding: 50px;
  box-sizing: border-box;
}

.App-avatar {
  width: 30%;
  min-width: 220px;
  text-align: center;
}

.App-avatar-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  image-rendering: pixelated;
  margin-bottom: 10px;
}

.App-content {
  width: 70%;
  box-sizing: border-box;
  padding: 0px 0px 0px 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-buttons a {
  margin: 10px;
  font-size: 32px;
  color: #6d7381;
}

.App-buttons a:hover {
  color: #777e8d;
}

.Project, .Publication, .Talk {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Publication > *, .Project > *, .Talk > * {
  margin: 0px;
}

.Publication h4, .Project h4, .Talk > h4 {
  font-size: 12pt;
}

.Talk-annotation {
  font-size: 10pt;
  font-style: italic;
  margin: 0px;
}

.App-copyright {
  font-size: 10px;
  color: #AAA;
}

@media only screen and (max-width: 850px) {
  .App {
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .App-content {
    margin-top: 20px;
    width: 100%;
    padding: 0px;
  }
}
